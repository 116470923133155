import { Box, SxProps } from '@mui/material';
import { styled } from '@mui/system';
import { InputHTMLAttributes, ReactNode, useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import watch from 'assets/images/watch.svg';
import watchFilled from 'assets/images/watch-filled.svg';

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  sx?: SxProps<any>;
  icon?: string;
  register?: UseFormRegisterReturn;
  errorMessage?: ReactNode;
  width?: number | string;
  StartComponent?: ReactNode;
}

const StyledBox = styled(Box)({
  border: '2px solid #4C9AFF',
  borderRadius: '3px',
  backgroundColor: '#fff',
  '& input': {
    padding: '8px 6px',
    font: 'inherit',
    border: 'none',
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
    width: '100%',
    '&::placeholder': {
      fontSize: '14px',
      color: '#7A869A',
    },
    flexGrow: 1,
  },
  display: 'flex',
});

export const TextInput = ({
  sx,
  icon,
  register,
  errorMessage,
  width,
  StartComponent,
  ...props
}: TextInputProps) => {
  return (
    <Box width={width}>
      <StyledBox sx={sx}>
        {!!StartComponent && StartComponent}
        <input {...props} {...register} />
        {!!icon && (
          <Box
            display="flex"
            py={1}
            alignItems="center"
            mr="10px"
            sx={{
              '& img': {
                width: '20px',
              },
            }}
          >
            <Box height="100%" borderRight="1px solid #D9D9D9" mr="10px" />
            <img src={icon} alt="icon" />
          </Box>
        )}
      </StyledBox>
      <Box color="#FF6446" fontSize={12} height={16} pl={1}>
        {errorMessage}
      </Box>
    </Box>
  );
};

export const PasswordInput = ({
  sx,
  register,
  errorMessage,
  ...props
}: TextInputProps) => {
  const [visible, setVisible] = useState<boolean>(false);

  const handleChangeVisibility = () => {
    setVisible((s) => !s);
  };

  return (
    <Box>
      <StyledBox sx={sx}>
        <input {...props} {...register} type={visible ? 'text' : 'password'} />
        <Box display="flex" py={1} alignItems="center" mr="10px">
          <Box
            height="100%"
            borderRight="1px solid #D9D9D9"
            mr="10px"
            sx={{
              '& img': {
                maxWidth: '13px',
                maxHeight: '13px',
              },
            }}
          />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              cursor: 'pointer',
              '& img': {
                width: 20,
              },
            }}
            onClick={handleChangeVisibility}
          >
            {visible ? (
              <img src={watch.src} alt="hide password" />
            ) : (
              <img src={watchFilled.src} alt="show password" />
            )}
          </Box>
        </Box>
      </StyledBox>
      <Box color="#FF6446" fontSize={12} height={16} pl={1}>
        {errorMessage}
      </Box>
    </Box>
  );
};
